import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header className="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>Lauren & Gavin</h1>
        <p>27.02.2022</p>
      </div>
    </div>
    <div className="icon fa-chevron-down" onClick={props.onClick} />
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
