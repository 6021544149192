import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer` 
background-color: rgba(255,255,255,0.95); 
z-index: 1000;
padding: 1rem;
p {
    text-align: left;
    color: black;
    font-family: 'Raleway';
    font-weight: 400;
}

`

const Footer = (props) => (
    <FooterWrapper id="footer">
        <p className="copyright">Watercolour leaves by Fran Rutstein.</p>
        <p className="copyright">Website by Gavin Katz.</p>
    </FooterWrapper>
)

export default Footer
